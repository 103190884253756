import React from 'react'
import { Select, MenuItem, Box, FormControl, InputLabel } from '@material-ui/core'

import { MassMessageTemplateStatus } from '../../../graphql/types/mass-messaging'
import { useQueryParams } from '../../../utils'

interface IProps {
  setFilter: (value: MassMessageTemplateStatus | undefined) => void
}

export const MassMessageTemplateFilter: React.FC<IProps> = ({ setFilter }) => {
  const [{ query }, setQuery] = useQueryParams()

  const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const newValue = event.target.value as MassMessageTemplateStatus | 'all'
    const method = newValue === 'all' ? 'remove' : 'push'
    setQuery({ [event.target.name!]: newValue }, method)
    setFilter(newValue === 'all' ? undefined : newValue)
  }

  return (
    <Box minWidth={160}>
      <FormControl fullWidth variant='outlined' size='small'>
        <InputLabel id='message-select-label'>Статус</InputLabel>
        <Select
          labelId='message-select-label'
          id='message-select'
          value={query.status || 'all'}
          onChange={handleChange}
          label='Статус'
          name='status'
        >
          <MenuItem value='all'>Все</MenuItem>
          <MenuItem value={MassMessageTemplateStatus.draft}>Черновик</MenuItem>
          <MenuItem value={MassMessageTemplateStatus.moderating}>На модерации</MenuItem>
          <MenuItem value={MassMessageTemplateStatus.rejected}>Отказано</MenuItem>
          <MenuItem value={MassMessageTemplateStatus.approved}>Подтвержден</MenuItem>
        </Select>
      </FormControl>
    </Box>
  )
}
