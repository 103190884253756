import React from 'react'
import { ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction, IconButton } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'

interface IProps {
  title: string
  secondaryText?: string | React.ReactElement
  avatar?: React.ReactElement
  actions?: React.ReactElement
  onClick?: () => void
  onEdit?: () => void
  onDelete?: () => void
}

export const DataListItem: React.FC<IProps> = ({
  title,
  secondaryText,
  avatar,
  actions,
  onClick,
  onEdit,
  onDelete,
}) => {
  return (
    <ListItem button onClick={onClick}>
      {avatar && <ListItemAvatar>{avatar}</ListItemAvatar>}
      <ListItemText primary={title} secondary={secondaryText} />
      <ListItemSecondaryAction>
        {actions}
        {onEdit && (
          <IconButton edge='end' aria-label='edit' onClick={onEdit}>
            <EditIcon />
          </IconButton>
        )}
        {onDelete && (
          <IconButton edge='end' aria-label='delete' onClick={onDelete}>
            <DeleteIcon />
          </IconButton>
        )}
      </ListItemSecondaryAction>
    </ListItem>
  )
}
