import React from 'react'
import { Box, IconButton, Menu, MenuItem } from '@material-ui/core'
import { Person as AccountIcon } from '@material-ui/icons'
import { Link } from 'react-router-dom'
import { SmsBalanceWidget } from '../Widgets/SmsBalanceWidget'
import useStores from '../../stores/hooks/useStores'
import { UserRole } from '../../core/interfaces'
import { FirebaseNotificationListener } from '../Firebase/FirebaseNotificationListener'

export const HeaderMenu: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const { authStore } = useStores()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Box display='inline-flex' alignItems='center'>
      {authStore.userHasRoles([UserRole.ADMIN]) && (
        <Box mr={2}>
          {/* <FirebaseNotificationListener /> */}
          <SmsBalanceWidget />
        </Box>
      )}
      <IconButton color='inherit' onClick={handleClick}>
        <AccountIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleClose} component={Link} to='/profile'>
          Профиль
        </MenuItem>
        <MenuItem component={Link} to='/logout'>
          Выйти
        </MenuItem>
      </Menu>
    </Box>
  )
}
