import React from 'react'
import { CircularProgress, Button, ButtonProps } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

interface IProps extends ButtonProps {
  loading?: boolean
}

export const LoadableButton: React.FC<IProps> = ({ loading, disabled, children, ...props }) => {
  const classes = useStyles()

  return (
    <Button {...props} disabled={disabled || loading}>
      {loading && <CircularProgress size={20} className={classes.loader} />} {children}
    </Button>
  )
}

const useStyles = makeStyles(theme => ({
  loader: {
    marginRight: theme.spacing(2),
  },
}))
