import React, { useEffect, useRef } from 'react'
import { useSnackbar } from 'notistack'
import { Box, Dialog, DialogContent, DialogTitle } from '@material-ui/core'

import { useUpdateMassMessageTemplate } from '../../graphql/hooks/mass-messaging'
import { IMassMessageTemplate, IMassMessageTemplateInput } from '../../graphql/types/mass-messaging'
import { MassMessageTemplateForm } from './MassMessageTemplateForm'
import { Loader } from '../../components/UI'
import { MassMessageTemplateStatusLabel } from './components'

interface IProps {
  open: boolean
  onClose: () => void
  template: IMassMessageTemplate
}

export function MessageTemplateDialog({ open, onClose, template }: IProps) {
  const { enqueueSnackbar } = useSnackbar()
  const { updateMassMessageTemplateMutation, loading } = useUpdateMassMessageTemplate()
  const isMounted = useRef(true)

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  const handleSubmit = async (input: IMassMessageTemplateInput) => {
    try {
      const response = await updateMassMessageTemplateMutation({
        variables: {
          input: { id: template.id, ...input },
        },
      })

      if (response.data?.updateMassMessageTemplateByAdmin) {
        enqueueSnackbar('Шаблон отправлен', { variant: 'success' })
        onClose()
      }
    } catch (error) {
      console.log(error)
    }
  }

  if (loading) {
    return <Loader />
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth='sm'>
      <DialogTitle>{template.title}</DialogTitle>
      <DialogContent>
        <Box mb={2}>
          <MassMessageTemplateStatusLabel status={template.status} />
        </Box>
        <Box>
          <MassMessageTemplateForm template={template} onSubmit={handleSubmit} onCancel={onClose} />
        </Box>
      </DialogContent>
    </Dialog>
  )
}
