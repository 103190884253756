import React from 'react'
import { Box, styled, Typography } from '@material-ui/core'

import { MassMessageTemplateStatus } from '../../../graphql/types/mass-messaging'
import { green, grey, orange, red } from '@material-ui/core/colors'

const STATUSES_MAP: Array<{ status: MassMessageTemplateStatus; label: string; color: string }> = [
  { status: MassMessageTemplateStatus.draft, label: 'Черновик', color: grey[200] },
  { status: MassMessageTemplateStatus.moderating, label: 'На модерации', color: orange[300] },
  { status: MassMessageTemplateStatus.rejected, label: 'Отказан', color: red[200] },
  { status: MassMessageTemplateStatus.approved, label: 'Подтвержден', color: green[300] },
]

interface IProps {
  status: MassMessageTemplateStatus
}

export function MassMessageTemplateStatusLabel({ status }: IProps) {
  const item = STATUSES_MAP.find(s => s.status === status)

  if (!item) {
    return null
  }

  return (
    <StyledChip bgcolor={item.color}>
      <Typography variant='caption' color='textPrimary'>
        {item.label}
      </Typography>
    </StyledChip>
  )
}

const StyledChip = styled(Box)(({ theme }) => ({
  display: 'inline-block',
  borderRadius: 16,
  padding: `${theme.spacing(0.8)}px ${theme.spacing(1.6)}px`,
}))
