import React, { useState, useEffect, useRef } from 'react'
import { useFormik } from 'formik'
import { Box, Button, Card, CardContent, TextField, Typography } from '@material-ui/core'
import * as Yup from 'yup'
import { Alert } from '@material-ui/lab'
import { red } from '@material-ui/core/colors'

import {
  IMassMessageTemplate,
  IMassMessageTemplateInput,
  MassMessageTemplateStatus,
} from '../../graphql/types/mass-messaging'
import { LoadableButton } from '../../components/Form'

interface IProps {
  template: IMassMessageTemplate
  onSubmit: (values: IMassMessageTemplateInput) => Promise<void>
  onCancel: () => void
}

const MESSAGE_TEMPLATE_MAX_LENGTH = 1000

const createValidationSchema = (isReject: boolean) => {
  if (isReject) {
    return Yup.object().shape({
      rejectionReason: Yup.string()
        .required('Причина отказа обязательна для заполнения')
        .max(MESSAGE_TEMPLATE_MAX_LENGTH, `Максимальная длина ${MESSAGE_TEMPLATE_MAX_LENGTH} символов`),
    })
  } else {
    return Yup.object().shape({
      rejectionReason: Yup.string(), // You can adjust the schema for non-reject cases if needed
    })
  }
}

interface IFormValues extends IMassMessageTemplateInput {}

export function MassMessageTemplateForm({ template, onSubmit, onCancel }: IProps) {
  const [isReject, setIsReject] = useState(false)
  const [isRejectedReason, setIsRejectedReason] = useState(true)
  const isModerating = template.status === MassMessageTemplateStatus.moderating
  const mountedRef = useRef<boolean>(true)

  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  const formik = useFormik<IFormValues>({
    initialValues: {
      title: template.title,
      message: template.message,
      rejectionReason: template.rejectionReason ?? '',
      status: template.status,
    },
    validationSchema: createValidationSchema(isReject),
    async onSubmit(values, { setSubmitting }) {
      try {
        if (!mountedRef.current) {
          return
        }

        await onSubmit(values)

        if (mountedRef.current) {
          setSubmitting(false)

          setIsReject(false)
          setIsRejectedReason(true)
        }
      } catch (error) {
        console.error('Error submitting form:', error)
      }
    },
  })

  const { values, errors, isSubmitting, setFieldValue, submitForm } = formik

  const handleApprove = async () => {
    setIsReject(false)

    setFieldValue('status', MassMessageTemplateStatus.approved)
    submitForm()
  }

  const handleReject = async () => {
    setIsReject(true)

    if (isRejectedReason) {
      setIsRejectedReason(false)
    } else {
      setFieldValue('status', MassMessageTemplateStatus.rejected)
      submitForm()
    }
  }

  return (
    <Box>
      <Box mb={2}>
        <Card>
          <CardContent>
            <Typography variant='body1' component='div'>
              {values.message}
            </Typography>
          </CardContent>
        </Card>
      </Box>

      {template.status === MassMessageTemplateStatus.rejected && (
        <Box mb={2}>
          <Alert severity='error'>{template.rejectionReason}</Alert>
        </Box>
      )}

      {isReject && (
        <Box mb={2}>
          <TextField
            variant='outlined'
            label='Причина отказа'
            multiline
            rows={6}
            fullWidth
            value={values.rejectionReason}
            onChange={({ target }) => setFieldValue('rejectionReason', target.value)}
            error={Boolean(errors.rejectionReason)}
          />
          <Box>
            <Typography align='right' variant='body2' color='textSecondary'>
              {`${values.rejectionReason?.length}/${MESSAGE_TEMPLATE_MAX_LENGTH}`}
            </Typography>
          </Box>
        </Box>
      )}

      <Box py={2} display='flex' flexDirection='row' justifyContent='flex-end' alignItems='center'>
        <Box mr={1}>
          <Button onClick={onCancel} variant='outlined'>
            Закрыть
          </Button>
        </Box>
        {isModerating && (
          <>
            <Box mx={1} color={red[500]}>
              <LoadableButton loading={isSubmitting} variant='outlined' color='inherit' onClick={handleReject}>
                Отказать
              </LoadableButton>
            </Box>
            <Box ml={1}>
              <LoadableButton
                loading={isSubmitting}
                variant='contained'
                color='primary'
                onClick={handleApprove}
                disabled={isReject}
              >
                Одобрить
              </LoadableButton>
            </Box>
          </>
        )}
      </Box>
    </Box>
  )
}
