import React from 'react'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@material-ui/core'

interface IProps {
  open: boolean
  onConfirm: () => void
  onCancel: () => void
  message: string
}

export const ConfirmationDialog: React.FC<IProps> = ({ open, onConfirm, onCancel, message }) => {
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>Подтверждение удаления</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color='primary'>
          Отмена
        </Button>
        <Button onClick={onConfirm} color='secondary'>
          Удалить
        </Button>
      </DialogActions>
    </Dialog>
  )
}
