import React from 'react'
import { Box, Divider, Typography } from '@material-ui/core'

import { IPaginationInput } from '../../graphql/types/common'
import { useGetBusinessMailingsByAdmin } from '../../graphql/hooks/mass-messaging/useGetBusinessMailingsByAdmin'
import { IBusinessMailingsResponse } from '../../graphql/types/mass-messaging'
import { EmptyDataMessage } from '../../components/Data'
import { MassMessageMailingsItem } from './components'
import { Loader } from '../../components/UI'

interface IProps {
  handleOpen: (mailing: IBusinessMailingsResponse) => void
}
export function MassMessageMailingsList({ handleOpen }: IProps) {
  const pagination: IPaginationInput = { offset: 0, limit: 20 }

  const { businessMailings, total, loading } = useGetBusinessMailingsByAdmin(pagination)

  if (loading) {
    return <Loader />
  }

  return (
    <div>
      <Box p={2} display='flex' alignItems='center' justifyContent='space-between'>
        <Typography> Всего: {total}</Typography>
      </Box>
      {businessMailings.length > 0 ? (
        businessMailings.map(businessMailings => (
          <Box key={`mm-tpl-${businessMailings.id}`}>
            <Divider />
            <MassMessageMailingsItem
              businessMailings={businessMailings}
              handleOpen={() => handleOpen(businessMailings)}
            />
          </Box>
        ))
      ) : (
        <EmptyDataMessage />
      )}
    </div>
  )
}
