import React from 'react'
import { Box, styled, Card, CardActionArea, Typography } from '@material-ui/core'
import moment from 'moment'
import { green, red, orange, grey } from '@material-ui/core/colors'

import { IBusinessMailingsResponse, MassMessageStatus } from '../../../graphql/types/mass-messaging'
import { DATE_TIME_FORMAT } from '../../../core/constants'

interface IProps {
  businessMailings: IBusinessMailingsResponse
  handleOpen: (template: IBusinessMailingsResponse) => void
}

export const STATUSES_MAP: Array<{ status: MassMessageStatus; label: string; color: string }> = [
  { status: MassMessageStatus.COMPLETED, label: 'Завершено', color: green[300] },
  { status: MassMessageStatus.FAILED, label: 'Неудачно', color: red[200] },
  { status: MassMessageStatus.IN_PROGRESS, label: 'В процессе', color: orange[200] },
  { status: MassMessageStatus.QUEUE_CHECK, label: 'В очереди', color: grey[200] },
  { status: MassMessageStatus.PENDING, label: 'Ожидание', color: grey[200] },
]

export function MassMessageMailingsItem({ businessMailings, handleOpen }: IProps) {
  const item = STATUSES_MAP.find(s => s.status === businessMailings.status)
  return (
    <Card elevation={0}>
      <CardActionArea onClick={() => handleOpen(businessMailings)}>
        <Box p={2} display='flex' alignItems='center' justifyContent='space-between'>
          <Box flexGrow={1}>
            <Typography variant='subtitle1'>{businessMailings.mailingName}</Typography>
            <Typography variant='body2' color='textSecondary'>
              {moment(businessMailings.createdAt).format(DATE_TIME_FORMAT)}
            </Typography>
          </Box>
          <Box>
            <StyledChip bgcolor={item?.color}>
              <Typography variant='caption' color='textPrimary'>
                {item?.label}
              </Typography>
            </StyledChip>
          </Box>
        </Box>
      </CardActionArea>
    </Card>
  )
}
const StyledChip = styled(Box)(({ theme }) => ({
  display: 'inline-block',
  borderRadius: 16,
  padding: `${theme.spacing(0.8)}px ${theme.spacing(1.6)}px`,
}))
