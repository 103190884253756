import React, { useEffect, useRef } from 'react'
import { Box, Card, CardContent, Dialog, DialogContent, DialogTitle, Typography, Grid } from '@material-ui/core'
import moment from 'moment'

import { IBusinessMailingsResponse } from '../../graphql/types/mass-messaging'
import { useBusinessDetail } from '../../graphql/hooks/businesses/useBusinessDetail'
import { STATUSES_MAP } from './components'

interface IProps {
  open: boolean
  onClose: () => void
  mailing: IBusinessMailingsResponse
}

interface StatBoxProps {
  label: string
  value: number
  color?: string
}

const StatBox = ({ label, value, color = 'inherit' }: StatBoxProps) => (
  <Box
    p={2.5}
    bgcolor='white'
    borderRadius={2}
    style={{
      boxShadow: '0 1px 3px rgba(0,0,0,0.08)',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    }}
  >
    <Typography variant='body2' color='textSecondary' style={{ marginBottom: 8 }}>
      {label}
    </Typography>
    <Typography
      variant='h4'
      style={{
        fontWeight: 500,
        color,
        lineHeight: 1,
        fontFamily: 'Inter, sans-serif',
      }}
    >
      {value}
    </Typography>
  </Box>
)

export function MassMessageMailingDialog({ open, onClose, mailing }: IProps) {
  const isMounted = useRef(true)
  const { business } = useBusinessDetail(mailing.businessId)

  const item = STATUSES_MAP.find(s => s.status === mailing.status)

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth='sm'
      PaperProps={{
        style: {
          borderRadius: 16,
          padding: '24px 16px',
        },
      }}
    >
      <DialogTitle>
        <Box display='flex' justifyContent='space-between' alignItems='center' mb={1}>
          <Typography variant='h5' style={{ fontWeight: 600 }}>
            {mailing.mailingName}
          </Typography>
          <Typography
            variant='body2'
            style={{
              backgroundColor: item?.color || '#475569',
              color: '#fff',
              padding: '6px 12px',
              borderRadius: '6px',
              fontSize: '0.875rem',
            }}
          >
            {item?.label}
          </Typography>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Box>
          <Card elevation={0} style={{ backgroundColor: '#f8fafc', borderRadius: 12, marginBottom: 32 }}>
            <CardContent>
              <Typography variant='subtitle1' style={{ fontWeight: 600, marginBottom: 12 }}>
                Информация о компании
              </Typography>
              <Typography variant='h6' style={{ fontWeight: 500 }}>
                {business?.companyName}
              </Typography>
              {business?.address && (
                <Typography variant='body2' color='textSecondary' style={{ marginTop: 4 }}>
                  {business.address}
                </Typography>
              )}
            </CardContent>
          </Card>

          {/* Детали рассылки */}
          <Card elevation={0} style={{ backgroundColor: '#f8fafc', borderRadius: 12 }}>
            <CardContent>
              <Typography variant='subtitle1' style={{ fontWeight: 600, marginBottom: 16 }}>
                Детали рассылки
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Box>
                    <Typography variant='body2' color='textSecondary' gutterBottom>
                      Номер телефона
                    </Typography>
                    <Typography variant='body1' style={{ fontWeight: 500 }}>
                      {mailing.mailingPhoneNumber}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box>
                    <Typography variant='body2' color='textSecondary' gutterBottom>
                      Дата отправки
                    </Typography>
                    <Typography variant='body1' style={{ fontWeight: 500 }}>
                      {moment(mailing.createdAt).format('DD.MM.YYYY HH:mm')}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          {/* Статистика */}
          <Card elevation={0} style={{ backgroundColor: '#f8fafc', borderRadius: 12 }}>
            <CardContent>
              <Typography variant='subtitle1' style={{ fontWeight: 600, marginBottom: 16 }}>
                Статистика отправки
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <StatBox label='Всего получателей' value={mailing.totalRecipients} color='#334155' />
                </Grid>
                <Grid item xs={4}>
                  <StatBox label='Доставлено' value={mailing.totalSuccess} color='#0F766E' />
                </Grid>
                <Grid item xs={4}>
                  <StatBox label='Не доставлено' value={mailing.totalFailed} color='#991B1B' />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
