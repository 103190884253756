import React from 'react'
import { Box, Card, CardActionArea, Typography } from '@material-ui/core'
import moment from 'moment'

import { IMassMessageTemplate } from '../../../graphql/types/mass-messaging'
import { DATE_TIME_FORMAT } from '../../../core/constants'
import { MassMessageTemplateStatusLabel } from './MassMessageTemplateStatusLabel'

interface IProps {
  template: IMassMessageTemplate
  handleOpen: (template: IMassMessageTemplate) => void
}

export function MassMessageTemplateItem({ template, handleOpen }: IProps) {
  return (
    <Card elevation={0}>
      <CardActionArea onClick={() => handleOpen(template)}>
        <Box p={2} display='flex' alignItems='center' justifyContent='space-between'>
          <Box flexGrow={1}>
            <Typography variant='subtitle1'>{template.title}</Typography>
            <Typography variant='body2' color='textSecondary'>
              {moment(template.createdAt).format(DATE_TIME_FORMAT)}
            </Typography>
          </Box>
          <Box>
            <MassMessageTemplateStatusLabel status={template.status} />
          </Box>
        </Box>
      </CardActionArea>
    </Card>
  )
}
