import React, { useState } from 'react'
import { Box } from '@material-ui/core'

import { Content, PageHeader } from '../../components/Layout'
import { MassMessageTemplatesList, MessageTemplateDialog } from '../../sections/message-templates'
import { IMassMessageTemplate } from '../../graphql/types/mass-messaging'

function MessageTemplates() {
  const [selectedTemplate, setSelectedTemplate] = useState<IMassMessageTemplate | undefined>()

  const handleOpenDialog = (template?: IMassMessageTemplate) => {
    setSelectedTemplate(template)
  }

  const handleCloseDialog = () => {
    setSelectedTemplate(undefined)
  }

  return (
    <Box>
      <PageHeader title={'Шаблоны сообщении'} />
      <Content noGutter>
        <MassMessageTemplatesList handleOpen={handleOpenDialog} />
      </Content>

      {!!selectedTemplate && <MessageTemplateDialog open template={selectedTemplate} onClose={handleCloseDialog} />}
    </Box>
  )
}

export default MessageTemplates
