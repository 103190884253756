import React from 'react'
import { useMutation } from '@apollo/react-hooks'
import { Box, Dialog, DialogTitle, DialogContent } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { CorporatesForm } from './CorporatesForm'
import { CREATE_CORPORATION, UPDATE_CORPORATIONS } from '../../../graphql/queries/businesses'

interface IProps {
  open: boolean
  onCancel: () => void
  onSubmit: (id: string | null, corporationName: string) => void
  initialData?: { id: string; corporationName: string } | null
}

export const CorporatesFormModal: React.FC<IProps> = ({ open, onCancel, onSubmit, initialData }) => {
  const [createOrUpdateCorporation, { loading, error }] = useMutation(
    initialData ? UPDATE_CORPORATIONS : CREATE_CORPORATION
  )

  const handleSubmit = async (values: { corporationName: string }) => {
    await onSubmit(initialData?.id || null, values.corporationName)
    onCancel()
  }

  return (
    <Dialog open={open} maxWidth='sm' fullWidth>
      <DialogTitle id='form-dialog-title'>{initialData ? 'Обновление корпорации' : 'Создание корпорации'}</DialogTitle>
      <DialogContent>
        {!!error && (
          <Box mb={2}>
            <Alert severity='error'>{error.message}</Alert>
          </Box>
        )}
        <Box>
          <CorporatesForm
            loading={loading}
            initialValues={initialData || { corporationName: '' }}
            onSubmit={handleSubmit}
            onCancel={onCancel}
          />
        </Box>
      </DialogContent>
    </Dialog>
  )
}
