import { IPaginatedResponse, IPaginationInput } from './common'

export enum MassMessageTemplateStatus {
  draft = 0,
  moderating = 1,
  rejected = 2,
  approved = 3,
}

export enum PaymentStatus {
  FREE = 'FREE',
  PAID = 'PAID',
  PENDING = 'PENDING',
  FAILED = 'FAILED',
}

export enum MassMessageStatus {
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  QUEUE_CHECK = 'QUEUE_CHECK',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}

export interface IMassMessageTemplate {
  id: number
  businessId: number
  status: MassMessageTemplateStatus
  title: string
  message: string
  rejectionReason: string | null
  createdAt: Date
}

export interface IMassMessageTemplateInput {
  id?: number
  title?: string
  message: string
  rejectionReason?: string
  status: MassMessageTemplateStatus
}

export interface IMassMessageFilterInput {
  status?: MassMessageTemplateStatus
  businessId?: number
}

export interface IMassMessageTemplatesByAdminData {
  massMessageTemplatesByAdmin: IPaginatedResponse<IMassMessageTemplate>
}

export interface IMassMessageTemplatesByAdminVariables {
  pagination: IPaginationInput
  filter: IMassMessageFilterInput
}

export interface IBusinessMailingsResponse {
  id: number
  mailingName: string
  mailingPhoneNumber: string
  businessId: number
  status: MassMessageStatus
  paymentStatus: PaymentStatus
  templateId: number
  totalRecipients: number
  totalSuccess: number
  totalFailed: number
  createdAt: Date
}

export interface IBusinessMailingsData {
  getBusinessMailingsByAdmin: {
    businessMailings: IBusinessMailingsResponse[]
    total: number
  }
}

export interface IBusinessMailingsVariables {
  pagination: IPaginationInput
}
