import { useMutation } from '@apollo/react-hooks'
import { IMassMessageTemplate, IMassMessageTemplateInput } from '../../types/mass-messaging'
import { UPDATE_MASS_MESSAGE_TEMPLATE, MASS_MESSAGE_TEMPLATES_BY_ADMIN } from '../../queries/mass-messaging'

export const useUpdateMassMessageTemplate = () => {
  const [updateMassMessageTemplateMutation, { loading }] = useMutation<
    { updateMassMessageTemplateByAdmin: IMassMessageTemplate },
    { input: IMassMessageTemplateInput }
  >(UPDATE_MASS_MESSAGE_TEMPLATE, {
    refetchQueries: [
      {
        query: MASS_MESSAGE_TEMPLATES_BY_ADMIN,
        variables: { pagination: { offset: 0, limit: 20 }, filter: { status: undefined } },
      },
    ],
  })

  return { updateMassMessageTemplateMutation, loading }
}
