import { useQuery } from '@apollo/react-hooks'

import { IPaginationInput } from '../../types/common'
import { IBusinessMailingsData, IBusinessMailingsVariables } from '../../types/mass-messaging'
import { GET_BUSINESS_MAILINGS_BY_ADMIN } from '../../queries/mass-messaging'

export const useGetBusinessMailingsByAdmin = (pagination: IPaginationInput) => {
  const { data, ...q } = useQuery<IBusinessMailingsData, IBusinessMailingsVariables>(GET_BUSINESS_MAILINGS_BY_ADMIN, {
    variables: { pagination },
    fetchPolicy: 'network-only',
  })

  const businessMailings = data?.getBusinessMailingsByAdmin.businessMailings ?? []
  const total = data?.getBusinessMailingsByAdmin.total ?? 0

  return {
    businessMailings,
    total,
    ...q,
  }
}
