import { useQuery } from '@apollo/react-hooks'
import { IPaginationInput } from '../../types/common'
import {
  IMassMessageFilterInput,
  IMassMessageTemplatesByAdminData,
  IMassMessageTemplatesByAdminVariables,
} from '../../types/mass-messaging'
import { MASS_MESSAGE_TEMPLATES_BY_ADMIN } from '../../queries/mass-messaging'

export const useMassMessageTemplatesByAdmin = (pagination: IPaginationInput, filter: IMassMessageFilterInput) => {
  const { data, ...q } = useQuery<IMassMessageTemplatesByAdminData, IMassMessageTemplatesByAdminVariables>(
    MASS_MESSAGE_TEMPLATES_BY_ADMIN,
    {
      variables: { pagination, filter },
      fetchPolicy: 'network-only',
    }
  )

  const templates = data?.massMessageTemplatesByAdmin.data ?? []
  const total = data?.massMessageTemplatesByAdmin.total ?? 0

  return {
    templates,
    total,
    ...q,
  }
}
