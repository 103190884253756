import gql from 'graphql-tag'
import { REVIEW_FRAGMENT } from './reviews'

export const FILTERED_BUSINESS_TRANSACTIONS_QUERY = gql`
  query FilteredBusinessTransactions(
    $filter: BusinessTransactionFilterInput
    $pagination: PaginationInput
    $sortBy: BusinessTransactionSortInput
  ) {
    filteredBusinessTransactions(filter: $filter, pagination: $pagination, sortBy: $sortBy) {
      transactions {
        id
        amount
        direction
        type
        typeComment
        paymentType
        status
        psPaymentId
        paymentDate
        description
        error
        errorCode
        balanceType
        business {
          id
          companyName
          country {
            id
            name
            numberCode
            code
            currencyCode
            currencySymbol
            minTransactionFee
            registrationPrice
          }
          promocodes {
            id
            promocode {
              title
              discount
              discountType
              discountValue
              salesManager {
                id
                user {
                  phone
                  email
                  id
                  fullName
                }
              }
            }
          }
        }
        transactionFee
        transactionFeeType
        transaction {
          id
          cashbackPercent
          amount
          createdAt
          review {
            ...ReviewFragment
          }
          purchaseToken
        }
        executedBy {
          id
          phone
          fullName
        }
        createdAt
        updatedAt
      }
      amount
      total
    }
  }
  ${REVIEW_FRAGMENT}
`

export const FILTERED_BUSINESS_TRANSACTIONS_XLSX_MUTATION = gql`
  mutation FilteredBusinessTransactionsXLSX($filter: BusinessTransactionFilterInput!, $timeZoneOffset: Int) {
    filteredBusinessTransactionsXLSX(responseType: url, filter: $filter, timeZoneOffset: $timeZoneOffset)
  }
`

export const TRANSACTION_REPORT_BY_BUSINESS_XLSX_MUTATION = gql`
  mutation TransactionReportByBusinessXLSX($businessId: Int, $filter: TransactionsFilterInput, $timeZoneOffset: Int) {
    transactionReportByBusinessXLSX(
      businessId: $businessId
      responseType: url
      filter: $filter
      timeZoneOffset: $timeZoneOffset
    )
  }
`
