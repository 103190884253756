import React, { useState } from 'react'
import { Box, Divider, Typography } from '@material-ui/core'

import { useMassMessageTemplatesByAdmin } from '../../graphql/hooks/mass-messaging'
import { IMassMessageTemplate, MassMessageTemplateStatus } from '../../graphql/types/mass-messaging'
import { IPaginationInput } from '../../graphql/types/common'
import { EmptyDataMessage } from '../../components/Data'
import { Loader } from '../../components/UI'
import { MassMessageTemplateItem, MassMessageTemplateFilter } from './components'

interface IProps {
  handleOpen: (template: IMassMessageTemplate) => void
}

export function MassMessageTemplatesList({ handleOpen }: IProps) {
  const [filter, setFilter] = useState<MassMessageTemplateStatus | undefined>(undefined)
  const pagination: IPaginationInput = { offset: 0, limit: 20 }

  const { templates, total, loading } = useMassMessageTemplatesByAdmin(pagination, { status: filter })

  if (loading) {
    return <Loader />
  }

  return (
    <div>
      <Box p={2} display='flex' alignItems='center' justifyContent='space-between'>
        <Typography> Всего: {total}</Typography>
        <MassMessageTemplateFilter setFilter={setFilter} />
      </Box>
      {templates.length > 0 ? (
        templates.map(template => (
          <Box key={`mm-tpl-${template.id}`}>
            <Divider />
            <MassMessageTemplateItem template={template} handleOpen={() => handleOpen(template)} />
          </Box>
        ))
      ) : (
        <EmptyDataMessage />
      )}
    </div>
  )
}
