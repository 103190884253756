import React, { useState } from 'react'
import { Box } from '@material-ui/core'

import { IBusinessMailingsResponse } from '../../graphql/types/mass-messaging'
import { MassMessageMailingDialog, MassMessageMailingsList } from '../../sections/message-mailings'
import { Content, PageHeader } from '../../components/Layout'

function MessageMailings() {
  const [selectedMailing, setSelectedMailing] = useState<IBusinessMailingsResponse | undefined>()

  const handleOpenDialog = (mailing?: IBusinessMailingsResponse) => {
    setSelectedMailing(mailing)
  }

  const handleCloseDialog = () => {
    setSelectedMailing(undefined)
  }
  return (
    <Box>
      <PageHeader title={'Рассылки'} />
      <Content noGutter>
        <MassMessageMailingsList handleOpen={handleOpenDialog} />
      </Content>

      {!!selectedMailing && <MassMessageMailingDialog open mailing={selectedMailing} onClose={handleCloseDialog} />}
    </Box>
  )
}

export default MessageMailings
