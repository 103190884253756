import React, { useState } from 'react'
import { useMutation, useQuery } from '@apollo/react-hooks'

import { Content, PageHeader } from '../../components/Layout'
import { CreateFab, Loader } from '../../components/UI'
import { DataList, EmptyDataMessage } from '../../components/Data'
import { CorporatesFormModal } from './components/CorporatesFormModal'
import { ConfirmationDialog } from './components/ConfirmationDialog'
import {
  CREATE_CORPORATION,
  UPDATE_CORPORATIONS,
  DELETE_CORPORATION,
  GET_CORPORATIONS_BY_ADMIN,
} from '../../graphql/queries/businesses'
import { GetCorporationsDataByAdmin } from '../../graphql/types/businesses'

const Corporates: React.FC = () => {
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [selectedCorporation, setSelectedCorporation] = useState<{ id: string; corporationName: string } | null>(null)
  const [isDeleteDialogVisible, setDeleteDialogVisible] = useState<boolean>(false)
  const [corporationToDelete, setCorporationToDelete] = useState<{ id: string; corporationName: string } | null>(null)

  const { data, loading, error } = useQuery<GetCorporationsDataByAdmin>(GET_CORPORATIONS_BY_ADMIN, {
    fetchPolicy: 'network-only',
  })

  const [createCorporation] = useMutation(CREATE_CORPORATION, {
    refetchQueries: [{ query: GET_CORPORATIONS_BY_ADMIN }],
  })

  const [updateCorporation] = useMutation(UPDATE_CORPORATIONS, {
    refetchQueries: [{ query: GET_CORPORATIONS_BY_ADMIN }],
  })

  const [deleteCorporation] = useMutation(DELETE_CORPORATION, {
    refetchQueries: [{ query: GET_CORPORATIONS_BY_ADMIN }],
  })

  const toggleModal = () => {
    setModalVisible(!modalVisible)
  }

  const handleCreateOrUpdateCorporation = async (id: string | null, corporationName: string) => {
    if (id) {
      await updateCorporation({ variables: { id, corporationName } })
    } else {
      await createCorporation({ variables: { corporationName } })
    }
    toggleModal()
  }

  const handleEdit = (corporation: { id: string; corporationName: string }) => {
    setSelectedCorporation(corporation)
    toggleModal()
  }

  const handleDelete = async () => {
    if (corporationToDelete) {
      await deleteCorporation({ variables: { id: corporationToDelete.id } })
      setDeleteDialogVisible(false)
      setCorporationToDelete(null)
    }
  }

  const openDeleteDialog = (corporation: { id: string; corporationName: string }) => {
    setCorporationToDelete(corporation)
    setDeleteDialogVisible(true)
  }

  if (loading) return <Loader />
  if (error) return <p>Error: {error.message}</p>

  return (
    <div>
      <PageHeader title='Корпорации' />
      {data?.getCorporationsByAdmin.length === 0 && !loading ? (
        <EmptyDataMessage />
      ) : (
        <Content noGutter>
          <DataList
            data={data?.getCorporationsByAdmin || []}
            title='corporationName'
            secondaryText={item => `Корпоративный код: ${item.token}`}
            onEdit={handleEdit}
            onDelete={openDeleteDialog}
          />
        </Content>
      )}
      <CreateFab onClick={toggleModal} />
      {modalVisible && (
        <CorporatesFormModal
          open={modalVisible}
          onCancel={() => {
            toggleModal()
            setSelectedCorporation(null)
          }}
          onSubmit={handleCreateOrUpdateCorporation}
          initialData={selectedCorporation}
        />
      )}
      {isDeleteDialogVisible && (
        <ConfirmationDialog
          open={isDeleteDialogVisible}
          onCancel={() => setDeleteDialogVisible(false)}
          onConfirm={handleDelete}
          message={`Вы уверены, что хотите удалить корпорацию "${corporationToDelete?.corporationName}"?`}
        />
      )}
    </div>
  )
}

export default Corporates
