import React from 'react'
import { Box, Button, TextField, makeStyles } from '@material-ui/core'
import { Formik, FormikProps } from 'formik'
import * as Yup from 'yup'

interface IProps {
  loading: boolean
  onSubmit: (values: { corporationName: string }) => void
  onCancel: () => void
  initialValues: { corporationName: string }
}

const validationSchema = Yup.object({
  corporationName: Yup.string().required('Название корпорации обязательно'),
})

export const CorporatesForm: React.FC<IProps> = ({ loading, onSubmit, onCancel, initialValues }) => {
  const classes = useStyles()

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ values, errors, handleChange, submitForm }: FormikProps<{ corporationName: string }>) => (
        <div>
          <Box mb={4}>
            <TextField
              label='Название корпорации'
              name='corporationName'
              value={values.corporationName}
              onChange={handleChange}
              required
              fullWidth
              margin='normal'
              error={!!errors.corporationName}
              helperText={errors.corporationName}
            />
          </Box>
          <Box pt={4} pb={1} display='flex' flexDirection='row' justifyContent='space-between'>
            <Button onClick={onCancel}>Отмена</Button>
            <Button
              color='primary'
              variant='contained'
              disabled={loading}
              onClick={submitForm}
              className={classes.submitButton}
            >
              {loading ? 'Сохранение...' : 'Сохранить'}
            </Button>
          </Box>
        </div>
      )}
    </Formik>
  )
}

const useStyles = makeStyles(theme => ({
  submitButton: {
    minWidth: '150px',
  },
}))
